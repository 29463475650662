/**
 *  Main Menu Json
 */


const features = {
   "menu_title": "Features",
   "type": "childsubMenu",
   "path": "#",
   "icon": "arrow_right_alt",
   "child_routes": [
      {
         "menu_title": "School Operations",
         "path": "/operations",
         "icon": "home",
         "child_routes": null
      },
      {
         "menu_title": "Modern Assessments",
         "path": "/assessments",
         "icon": "home",
         "child_routes": null
      },
      {
         "menu_title": "Academic Excellence",
         "path": "/academics",
         "icon": "home",
         "child_routes": null
      },
      {
         "menu_title": "Real-time Insights",
         "path": "/insights",
         "icon": "home",
         "child_routes": null
      },
      {
         "menu_title": "Talented Staff",
         "path": "/staff",
         "icon": "home",
         "child_routes": null
      },

   ]
}

const benefits = {
   "menu_title": "Benefits",
   "type": "childsubMenu",
   "path": "#",
   "icon": "arrow_right_alt",
   "child_routes": [
      {
         "menu_title": "For Schools",
         "path": "/schools",
         "icon": "home",
         "child_routes": null
      },
      {
         "menu_title": "For Teachers",
         "path": "/teachers",
         "icon": "home",
         "child_routes": null
      },
      {
         "menu_title": "For Parents",
         "path": "/parents",
         "icon": "home",
         "child_routes": null
      },
      {
         "menu_title": "For Students",
         "path": "/students",
         "icon": "home",
         "child_routes": null
      },
   ]
}

const reachus = {
   "menu_title": "Reach Us",
   "type": "childsubMenu",
   "path": "#",
   "icon": "arrow_right_alt",
   "child_routes": [
      {
         "menu_title": "Referral Bonus",
         "path": "/referral",
         "icon": "home",
         "child_routes": null
      },
      {
         "menu_title": "Discounts",
         "path": "/discounts",
         "icon": "home",
         "child_routes": null
      },
      {
         "menu_title": "Take a Free Tour",
         "path": "/tour",
         "icon": "home",
         "child_routes": null
      },
   ]
}

export default [
   {
      "menu_title": "Home",
      "path": "/",
      "icon": "home",
   },
   {
      "menu_title": "Platform",
      "type": "subMenu",
      "path": "#",
      "icon": "home",
      "child_routes": [
         { ...features },
         { ...benefits },
         { ...reachus },


      ]
   },
   {
      "menu_title": "Pricing",
      "path": "/pricing",
      "icon": "home",
   },
   {
      "menu_title": "Why Us",
      "path": "/whyus",
      "icon": "home",
   },
   {
      "menu_title": "Help Desk",
      "path": "/help",
      "icon": "home",
   },
   {
      "menu_title": "Login",
      "path": "https://app.digitalpatashala.com/session/login",
      "icon": "home",
      "type": "button"
   },
   {
      "menu_title": "SignUp",
      "path": "https://app.digitalpatashala.com/session/registration",
      "icon": "home",
      "type": "button"
   }
] 
