import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControlLabel-label": {
      fontWeight: "bold",
      fontSize: "12px"
    }
  },
  grayCheck: {
    color: "rgba(0,0,0,.4)"
  }
}));
function RadioButton(props) {
  const classes = useStyles();
  console.log(props.checked);
  return (
    <>
      <FormControl component="fieldset">
        <RadioGroup
          className={classes.root}
          aria-label="gender"
          // value={value}
          // onChange={handleChange}
        >
          <FormControlLabel
            name={props.name}
            value={props.name}
            checked={props.checked ? true : false}
            control={
              props.checked ? (
                <>
                  <CheckCircleIcon color="primary" />
                  &nbsp;&nbsp;
                </>
              ) : (
                <>
                  <CheckCircleOutlineIcon
                    className={classes.grayCheck}
                    color="textSecondary"
                  />
                  &nbsp;&nbsp;
                </>
              )
            }
            label={`${props.label}`}
          />
        </RadioGroup>
      </FormControl>
    </>
  );
}

export default RadioButton;
