import {
  FormControl,
  makeStyles,
  MenuItem,
  TextField
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%"
  }
}));
function SelectOption(props) {
  const classes = useStyles();
  const { item, onChange, onBlur, error, value, touched, adornment } = props;
  const { title, options, name } = item;
  return (
    <FormControl className={classes.formControl}>
      <TextField
        select
        labelId="demo-simple-select-label"
        label={title}
        id={name}
        value={value}
        name={name}
        variant="outlined"
        onChange={onChange}
        onBlur={onBlur}
        error={touched && Boolean(error)}
        helperText={touched && error}
        fullWidth

        // InputProps={{
        //   startAdornment: (
        //     <InputAdornment position="start">
        //       {/* <Flags.US title="United States"/> */}
        //       <img src={India} alt="indian flag"
        //       style={{width:"30px"}} /> &nbsp;India
        //     </InputAdornment>
        //   ),
        // }}
        // onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options.map((chunk, index) => {
          return (
            <MenuItem key={chunk.id} value={chunk.value}>
              {chunk.valueLabel}
            </MenuItem>
          );
        })}
      </TextField>
    </FormControl>
  );
}

export default SelectOption;
