import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
// import Car from "../assets/img/car.jpg";
// import Spinner from "./Spinner";

const CLIENT = {
    sandbox: "ARH0te2qPGKieEsux3W7iYTfmWxb4DfchEc0QvDc6G-sChKMiYSsxVtZGY0o1zdBYTuivy5LMm8_f9O0",
    production: "ARH0te2qPGKieEsux3W7iYTfmWxb4DfchEc0QvDc6G-sChKMiYSsxVtZGY0o1zdBYTuivy5LMm8_f9O0"
};

const CLIENT_ID =
    process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox;

let PayPalButton = null;
class PaypalButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showButtons: false,
            loading: true,
            paid: false
        };

        window.React = React;
        window.ReactDOM = ReactDOM;
    }
     
    componentDidMount() {
        const { isScriptLoaded, isScriptLoadSucceed } = this.props;

        if (isScriptLoaded && isScriptLoadSucceed) {
            PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
            this.setState({ loading: false, showButtons: true });
        } else console.log(isScriptLoaded);
        
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

        const scriptJustLoaded =
            !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

        if (scriptJustLoaded) {
            if (isScriptLoadSucceed) {
                PayPalButton = window.paypal.Buttons.driver("react", {
                    React,
                    ReactDOM
                });
                this.setState({ loading: false, showButtons: true });
            }
        }
    }
    createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: +"Admission",
                    amount: {
                        currency_code: "USD",
                        value: 200
                    }
                }
            ]
        });
    };

    onApprove = (data, actions) => {
        actions.order.capture().then((details) => {
            const paymentData = {
                payerID: data.payerID,
                orderID: data.orderID
            };
            console.log("Payment Approved: ", paymentData);
            this.setState({ showButtons: false, paid: true });
        });
    };

   

    render() {
        const { showButtons, loading, paid } = this.state;
        const runTheFunction = () => {
        alert("You Admission Payment is complete. We would soon send you your enrollment number")
                            window.location.href = "http://app.digitalpatashala.com/session/login"
    }
        if(paid)
            runTheFunction();
        return (
            <div className="main" style={{textAlign: "center"}}>
                {/* {loading && "Loading...."} */}

                {showButtons && (
                    <div>
                        <div style={{ textAlign: "center" }}>
                            <h2>Total checkout Amount $200</h2>
                        </div>

                        <PayPalButton
                            createOrder={(data, actions) => this.createOrder(data, actions)}
                            onApprove={(data, actions) => this.onApprove(data, actions)}
                        />
                    </div>
                )}

                {paid && (
                    <div className="main">
                        {
                            runTheFunction()
                        }
                    </div>
                )}
            </div>
        );
    }
}

export default scriptLoader(
    `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}`
)(PaypalButton);
