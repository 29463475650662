import {
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import React from "react";
import SelectOption from "./common/SelectOption";
import UploadPhoto from "./Student/UploadPhoto";
import { useFormik } from "formik";
import * as yup from "yup";

const dataArr = [
  {
    title: "Academic Year",
    name: "year",
    options: [
      {
        id: 0,
        value: 2018,
        valueLabel: "2018"
      },
      {
        id: 1,
        value: 2019,
        valueLabel: "2019"
      },
      {
        id: 2,
        value: 2020,
        valueLabel: "2020"
      }
    ]
  },
  {
    title: "Admission for Class/Grade",
    name: "class",
    options: [
      {
        id: 0,
        value: 8,
        valueLabel: "8th"
      },
      {
        id: 1,
        value: 9,
        valueLabel: "9th"
      },
      {
        id: 2,
        value: 10,
        valueLabel: "10th"
      }
    ]
  },
  {
    title: "Branch",
    name: "branch",
    options: [
      {
        id: 0,
        value: 1,
        valueLabel: "Dilsukhnagar"
      },
      {
        id: 1,
        value: 2,
        valueLabel: "RK Puram"
      },
      {
        id: 2,
        value: 3,
        valueLabel: "Vanasthalipuram"
      },
      {
        id: 3,
        value: 4,
        valueLabel: "Karmanghat"
      },
      {
        id: 4,
        value: 5,
        valueLabel: "Badangpet"
      }
    ]
  },
  {
    title: "Admission Type",
    name: "admissionType",
    options: [
      {
        id: 0,
        value: 1,
        valueLabel: "Standard"
      },
      {
        id: 1,
        value: 2,
        valueLabel: "Military"
      },
      {
        id: 2,
        value: 3,
        valueLabel: "Scholarship Winner"
      }
    ]
  },
  {
    title: "Gender",
    name: "gender",
    options: [
      {
        id: 0,
        value: 1,
        valueLabel: "Male"
      },
      {
        id: 1,
        value: 2,
        valueLabel: "Female"
      }
    ]
  },
  {
    title: "First Language",
    name: "firstLanguage",
    options: [
      {
        id: 0,
        value: 1,
        valueLabel: "English"
      },
      {
        id: 1,
        value: 2,
        valueLabel: "Hindi"
      },
      {
        id: 2,
        value: 3,
        valueLabel: "Sanskrit"
      },
      {
        id: 3,
        value: 4,
        valueLabel: "Telugu"
      },
      {
        id: 4,
        value: 5,
        valueLabel: "Other"
      }
    ]
  },
  {
    title: "Second Language",
    name: "secondLanguage",
    options: [
      {
        id: 0,
        value: 1,
        valueLabel: "English"
      },
      {
        id: 1,
        value: 2,
        valueLabel: "Hindi"
      },
      {
        id: 2,
        value: 3,
        valueLabel: "Sanskrit"
      },
      {
        id: 3,
        value: 4,
        valueLabel: "Telugu"
      },
      {
        id: 4,
        value: 5,
        valueLabel: "Other"
      }
    ]
  },
  {
    title: "Board Type",
    name: "boardType",
    options: [
      {
        id: 0,
        value: 2018,
        valueLabel: "CBSE"
      },
      {
        id: 1,
        value: 2019,
        valueLabel: "ICSE"
      },
      {
        id: 2,
        value: 2020,
        valueLabel: "State Board"
      }
    ]
  },
  {
    title: "Category",
    name: "category",
    options: [
      {
        id: 0,
        value: 2018,
        valueLabel: "General"
      },
      {
        id: 1,
        value: 2019,
        valueLabel: "EWS"
      },
      {
        id: 1,
        value: 2019,
        valueLabel: "OBC"
      },
      {
        id: 2,
        value: 2020,
        valueLabel: "SC/ST"
      }
    ]
  },
  {
    title: "Economically Weaker Sec.",
    name: "weakSection",
    options: [
      {
        id: 0,
        value: 2018,
        valueLabel: "Yes"
      },
      {
        id: 1,
        value: 2019,
        valueLabel: "No"
      }
    ]
  }
];
const validationSchema = yup.object({
  // class: yup
  //   .number("Please enter a numerical value")
  //   .required("Class is required"),
  // year: yup
  //   .number("Please enter a numerical value")
  //   .required("Academic Year is required"),
  // branch: yup
  //   .number("Please enter a numerical value")
  //   .required("Branch is required"),
  // admissionType: yup
  //   .number("Please enter a numerical value")
  //   .required("Admission Type is required"),
  // gender: yup
  //   .number("Please enter a numerical value")
  //   .required("Gender is required"),
  // firstLanguage: yup
  //   .number("Please enter a numerical value")
  //   .required("First Language is required"),
  // secondLanguage: yup
  //   .number("Please enter a numerical value")
  //   .required("Second Language is required"),
  // name: yup.string().required("Name is required"),
  // dob: yup.date("Enter date").required("Date is required"),
  // pastSchool: yup.string().required("Previous School is required")
});
const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    height: "2px"
  },
  formControl: {
    width: "100%"
  }
}));
function Student(props) {
  const { setCurrentTab } = props;
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      class: "",
      year: "",
      branch: "",
      admissionType: "",
      gender: "",
      firstLanguage: "",
      secondLanguage: "",
      name: "",
      dob: "",
      pastSchool: ""
    },
    validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      setCurrentTab((prev) => prev + 1);
    }
  });
  return (
    <div>
      <Typography variant="h4">
        {/* <span
          style={{
            fontWeight: "bold",
            display: "block",
            margin: "20px 0 14px 0",
            textAlign: "center"
          }}
        >
          Student Details
        </span> */}
      </Typography>
      <Divider variant="fullWidth" className={classes.divider} />
      <Container>
        <UploadPhoto />
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item sm={6} xs={12}>
              <SelectOption
                item={dataArr[0]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.year}
                touched={formik.touched.year}
                error={formik.errors.year}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <SelectOption
                item={dataArr[7]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.boardType}
                touched={formik.touched.boardType}
                error={formik.errors.boardType}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item sm={6} xs={12}>
              <SelectOption
                item={dataArr[2]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.branch}
                touched={formik.touched.branch}
                error={formik.errors.branch}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <SelectOption
                item={dataArr[1]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.class}
                touched={formik.touched.class}
                error={formik.errors.class}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item sm={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="Student Name"
                name="name"
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                helperText={formik.touched.name && formik.errors.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                id="date"
                label="DOB"
                type="date"
                name="dob"
                variant="outlined"
                defaultValue="YYYY-MM-DD"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.dob}
                helperText={formik.touched.dob && formik.errors.dob}
                error={formik.touched.dob && Boolean(formik.errors.dob)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item sm={6} xs={12}>
              <SelectOption
                item={dataArr[4]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gender}
                touched={formik.touched.gender}
                error={formik.errors.gender}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <SelectOption
                item={dataArr[8]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.category}
                touched={formik.touched.category}
                error={formik.errors.category}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item sm={6} xs={12}>
              <SelectOption
                item={dataArr[9]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.weakSection}
                touched={formik.touched.weakSection}
                error={formik.errors.weakSection}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="Previous School Studied"
                variant="outlined"
                placeholder="Ex: Goutham Modal School"
                name="pastSchool"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pastSchool}
                helperText={
                  formik.touched.pastSchool && formik.errors.pastSchool
                }
                error={
                  formik.touched.pastSchool && Boolean(formik.errors.pastSchool)
                }
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6}>
              <SelectOption
                item={dataArr[5]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstLanguage}
                touched={formik.touched.firstLanguage}
                error={formik.errors.firstLanguage}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectOption
                item={dataArr[6]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondLanguage}
                touched={formik.touched.secondLanguage}
                error={formik.errors.secondLanguage}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                // onClick={(e)=>{
                //   e.preventDefault();

                //   formik.handleSubmit();
                // }}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default Student;
