import "babel-polyfill";
// import cssVars from "css-vars-ponyfill";

import React from "react";
import ReactDOM from "react-dom";
import history from "history.js";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core"

// import "./_index.scss";
import { Router, Switch, Route } from "react-router-dom"

import * as serviceWorker from "./serviceWorker";
// import App from "./app/App";
// import Home from "home/Home";
import Home2 from "home2/Home2";
import ComingSoon from "home2/pages/utilities/comingsoon";
import App from "home2/App"

import { rootReducer } from './home/reducers';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#00AEEF"
        },
        secondary: {
            main: "#00519B "
        }
    },
    overrides: {
        MuiButton: {
            root: {
                color: "#fff!important"
            }
        }
    }
})
// cssVars();
ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <ThemeProvider theme={theme}>
                <Switch>
                    <Route path="/" exact component={ComingSoon}></Route>
                    <Route exact component={App} />
                </Switch>
            </ThemeProvider>
        </Router>
    </Provider>
    , document.getElementById("root"));

// for IE-11 support un-comment cssVars() and it's import in this file
// and in EgretTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
