import React, { Component } from 'react';
import CountUp from 'react-countup';

class Counter2 extends Component {
  render() {
    return (

      <div className="row text-center">
        <div className="col-lg-6 col-md-6">
          <div className="counter bg-primary rounded p-5 shadow">
            <div className="counter-desc text-white">
              {/* <h5><i className="fa fa-car"></i>&nbsp;&nbsp;Schools</h5> */}
              <h5><i style={{ fontSize: "24px", color: "#fff", }} className="fas fa-school"></i>&nbsp;&nbsp;Schools</h5>
              <CountUp className="count-number display-4" end={1200} duration={100} />
              <span className="display-4">+</span>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 mt-5">
          <div className="counter bg-white rounded p-5 shadow">
            <div className="counter-desc">
              <h5><i style={{ fontSize: "24px" }} className="fas fa-car-side"></i>&nbsp;&nbsp;
              Cities</h5>
              <CountUp className="count-number display-4 text-primary" end={100} duration={100} />

              <span className="display-4 text-primary">+</span>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 mt-5 mt-md-0">
          <div className="counter bg-white rounded p-5 shadow">
            <div className="counter-desc">
              <h5><i style={{ fontSize: "24px", }} className="fas fa-book"></i>
              &nbsp;&nbsp;Lectures</h5>
              <CountUp className="count-number display-4 text-primary" end={365} duration={1000} />
              <span className="display-4 text-primary">+</span>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 mt-5">
          <div className="counter bg-orange rounded p-5 shadow">
            <div className="counter-desc text-white">
              <h5><i style={{ fontSize: "24px", color: "#fff", }} className="fas fa-business-time"></i>
              &nbsp;&nbsp;Assessments</h5>
              <CountUp className="count-number display-4" end={5000} duration={100} />
              <span className="display-4">+</span>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default Counter2;