import React, { Component } from 'react';
// import Header3 from '../../layout/header/header3';
// import Herosection1 from '../../widgets/herosection/herosection1';
import FeatureServices from '../../widgets/featurebox/services';
// import Counter1 from '../../widgets/counter/counter1';
// import About from '../../widgets/index/about';
// import Skillbox from '../../widgets/featurebox/skillbox'
// import Team1 from '../../widgets/team/team1';
// import Pricingplan1 from '../../widgets/pricingplan/pricingplan1';
// import Blog2 from '../../widgets/blog/blog2';
import About1 from '../../widgets/index/about1';
import ApproachBy from '../../widgets/index/approachBy';
import LeadingPlatform from 'home/widgets/index/leadingPlatform';
import Testimonial3 from '../../widgets/testimonial/testimonial3';
import Pricingplan5 from '../../widgets/pricingplan/pricingplan5';
import OwlCarousel from 'react-owl-carousel';
import Clientlogo from '../../widgets/common/Clientlogo';
import Header2 from '../../layout/header/header2';
import Counter2 from '../../widgets/counter/counter2';
import Herosection8 from '../../widgets/herosection/herosection8';
import ModalVideo from 'react-modal-video';


class Index2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    this.setState({ isOpen: true })
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        {/*header start*/}
        {/* <Header3 /> */}
        <Header2 />
        {/*header end*/}
        {/*hero section start*/}
        <section>
          <div className="container">
            <Herosection8 />
          </div>
        </section>
        {/*hero section end*/}
        {/*body content start*/}
        <div className="page-content">
          {/*services start*/}
          <section className="p-0">
            <div className="container">
              {/* <div className="row justify-content-center text-center">
                <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                  <div className="mb-8"> <span className="badge badge-primary-soft p-2 font-w-6">
                    What We Offer
                    </span>
                    <h2 className="mt-3 font-w-5">Creative Design And Creative Services</h2>
                    <p className="lead mb-0">All types of businesses need access to development resources, so we give you the option to decide how much you need to use.</p>
                  </div>
                </div>
              </div> */}
              {/* / .row */}
              <FeatureServices />
            </div>
          </section>
          {/*services end*/}
          {/*counter start*/}
          <section className="grayedSection" >
            <div className="container-fluid">
              <ModalVideo channel='youtube' isOpen={this.state.isOpen} autoplay={true} videoId='P_wKDMcr1Tg' onClose={() => this.setState({ isOpen: false })} />

              <div className="row align-items-center justify-content-between">
                <div className="col-12 col-lg-6 mb-8 mb-lg-0">
                  <img src={require(`../../assets/images/about/slider1.png`)} alt="Image" className="img-fluid" />
                  <div className="video-btn video-btn-pos">
                    <a className="play-btn popup-youtube" onClick={this.openModal}><i className="la la-play" /></a>
                    <div className="spinner-eff">
                      <div className="spinner-circle circle-1" />
                      <div className="spinner-circle circle-2" />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-5">
                  <Counter2 />
                </div>
              </div>
            </div>
          </section>

          {/*counter end*/}
          {/*about start*/}
          <section className="pt-0" style={{ marginTop: "26px" }}>
            <About1 heading="For Schools"
              para="One-Stop-Solution to manage and configure school related activities, administrative data and performance analytics. We provide Highly Interactive Management Solution for all kinds of Educational Institutes."
              first="AI Powered Solution" second="Cloud-Based Access"
              third="Staff, Parents and Students Management"
              fourth="100% Affordable"
              img="school.png"
              reverse
            />

          </section>
          <section className="pt-0">
            <About1 heading="For Teachers" para="Unique and visually appealing lesson content creating app/tool, excellent lesson scheduler, student performance tracker for teachers."
              first="Most Unique features" second="Lesson Authoring and Content Creation"
              third="Rich UI"
              fourth="Excellent Performance"
              img="teachers.png" />

          </section>
          <section className="pt-0">
            <About1 heading="For Parents"
              para="Track your kid’s academic performance dashboard and behavior feedback, get engaged with kids learning with ease using DP Parent Portal."
              first="Real-time kids performance tracker" second="Stay connected with teachers"
              third="Accessible anywhere anytime" fourth="Easy-to-use interface"
              img="parents.png"
              reverse
            />
          </section>
          <section className="pt-0">
            <About1 heading="For Students"
              para="Students get the Best Academic Foundation, AI Based Skill Enhancement Techniques, and User friendly e-learning Platform here in DP Student Portal."
              first="AI-Based Skill Enhancement" second="Performance Tracker"
              third="Lessons, Activities accessible anytime anywhere"
              fourth="Student Portfolio "
              img="slider2.png"
            />

          </section>
          {/*about end*/}
          {/* approachBy start */}
          <section className="pt-0">

            <ApproachBy heading="S.M.A.R.T Approach by DigitalPatashala"
              para="DigitalPatashala is a Unique, Advanced and AI-Powered School Management Software suitable for all Educational Ecosystems optimized for day to day operations, Staff Enrollment, Student e-learning and assessments, Parent-Kids-Teachers Engagement and many more... "
              first="School Operations"
              second="Modern Assessments"
              third="Academic Excellence"
              fourth="Real-time Insights"
              fifth="Talented Teachers"
              img="smart.png"
            />
          </section>
          {/* approachBy end */}


          {/*screenshots start*/}
          <section className=" grayedSection">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-md-12 col-lg-4 mb-8 mb-lg-0">
                  <div className="mb-0">
                    {/* <span className="badge badge-primary-soft p-2">
                    <i className="la la-file-image-o ic-3x rotation" />
                  </span> */}
                    <h2 className="mt-3 font-w-6">See Our Digital Patashala App Screenshots</h2>
                    <p className="lead mb-0">All types of Schools need access to decisive and management resources, so we give you the option to decide how much you need to use.</p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-8">
                  <OwlCarousel
                    className="owl-carousel owl-center no-pb"
                    dotData={false}
                    dots={false}
                    center={true}
                    items={3}
                    autoplay={true}
                    loop={true}
                  >
                    <div className="item">
                      <img className="img-fluid" src={require(`../../assets/images/app/01.png`)} alt="" />
                    </div>
                    <div className="item">
                      <img className="img-fluid" src={require(`../../assets/images/app/02.png`)} alt="" />
                    </div>
                    <div className="item">
                      <img className="img-fluid" src={require(`../../assets/images/app/03.png`)} alt="" />
                    </div>
                    <div className="item">
                      <img className="img-fluid" src={require(`../../assets/images/app/01.png`)} alt="" />
                    </div>
                    <div className="item">
                      <img className="img-fluid" src={require(`../../assets/images/app/02.png`)} alt="" />
                    </div>
                    <div className="item">
                      <img className="img-fluid" src={require(`../../assets/images/app/03.png`)} alt="" />
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>

          {/*screenshots end*/}

          {/*pricing start*/}
          <section>
            <div className="container">
              <div className="row align-items-end justify-content-between">
                <div className="col-12 col-md-6 col-lg-5 mb-5 mb-md-0">
                  <div>
                    {/* <span className="badge badge-primary-soft p-2 font-w-6">
                    Price Table
                    </span> */}
                    <h2 className="mt-3 font-w-5">Pricing</h2>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <nav>
                    <div className="nav nav-tabs d-flex justify-content-md-end border-0" id="nav-tab" role="tablist"> <a className="nav-item nav-link border-0 active" id="nav-tab-1" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true">Monthly</a>
                      <a className="nav-item nav-link border-0" id="nav-tab-2" data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false">Yearly</a>
                    </div>
                  </nav>
                </div>
              </div>
              {/* / .row */}
              <Pricingplan5 />
            </div>
          </section>

          {/*pricing end*/}



          {/*testimonial start*/}
          <Testimonial3 />
          {/*testimonial end*/}
          {/*client start*/}
          <section className="text-center p-0" style={{ marginTop: "40px" }}>
            <div className="container">
              <div className="row text-center">
                <div className="col-12 ">
                  <Clientlogo logoitems={6} margintop={0} />
                </div>
              </div>
            </div>
          </section>
          {/*client end*/}
          <br /> <br /> <br /> <br />
          <section className="pt-0">
            <LeadingPlatform heading="The Next-Gen School Management System for Institutions"
              heading1="About DigitalPatashala"
              para1="DigitalPatashala is an Effective, Advanced and Affordable Educational Institution Management Software with Unique and Amazing functionalities like AI-Powered Adaptive Personalized Digital Learning for Students, Performance Analyzers and Rich Content Creating Tools for Teachers. This makes DigitalPatashala a One-Stop-Solution to carry out all your daily Educational Institution Management activities. Our Go-Green Initiative enables Zero Paperwork Policy Implementation in all the Educational Institutions and Makes the World a better workplace."
              heading2="Our Vision"
              para2="Our Vision is to provide a One-Stop School Management Software ready for future generations by building a Technology Driven Environment to achieve excellence in Education."
            />
          </section>
          {/*feature start*/}
          {/* <Skillbox /> */}
          {/*feature end*/}
          {/*team start*/}
          {/* <section>
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                  <div className="mb-8"> <span className="badge badge-primary-soft p-2 font-w-6">
                    Bootsland Team
                        </span>
                    <h2 className="mt-3 font-w-6">Meet Our Team Of Expert</h2>
                    <p className="lead mb-0">All types of businesses need access to development resources, so we give you the option to decide how much you need to use.</p>
                  </div>
                </div>
              </div> */}
          {/* / .row */}
          {/* <Team1 />
            </div>
          </section> */}
          {/*team end*/}
          {/*pricing start*/}
          {/* <section>
            <div className="container-fluid">
              <Pricingplan1 />
            </div>
          </section> */}
          {/*pricing end*/}
          {/*blog start*/}
          {/* <section>
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                  <div className="mb-8"> <span className="badge badge-primary-soft p-2 font-w-6">
                    Latest News
                        </span>
                    <h2 className="mt-3 font-w-6">From Our Blog List Latest Feed</h2>
                    <p className="lead mb-0">All types of businesses need access to development resources, so we give you the option to decide how much you need to use.</p>
                  </div>
                </div>
              </div> */}
          {/* / .row */}
          {/* <Blog2 />
            </div>
          </section> */}
          {/*blog end*/}
        </div>
        {/*body content end*/}
      </div>
    );
  }
}

export default Index2;