import React, { Component } from 'react';
import CommingSoonCounter from '../../../widgets/common/counter';

class ComingSoon extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        let seconds = Math.abs(new Date(2021, 2, 21, 0, 0, 0, 0) - new Date()) / 1000;
        console.log(seconds / 60 / 60 / 24)
        console.log(new Date(2021, 1, 21, 0, 0, 0));
        console.log(new Date());
        return (
            <div className="page-content">
                {/*coming soon start*/}
                <section className="fullscreen-banner p-0 o-hidden bg-pos-r" data-bg-img={require(`../../../assets/images/coming-bg.png`)} style={{ height: '341px', backgroundImage: `url(${require('../../../assets/images/coming-bg.png')}` }}>
                    <div className="container-fluid px-md-8 h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-12">
                                <div className="shadow p-5 rounded" data-bg-color="rgba(255,255,255,0.2)">
                                    <div className="row justify-content-between">
                                        <div className="col-lg-5 col-md-12">
                                            <div className="coming-soon">
                                                <img className="img-fluid" src="/assets/logo.png"></img>
                                                <h1 className="mt-4 mb-2 font-w-7">Coming Soon</h1>
                                                <p>We Are Creating Something Awesome And Exciting For You</p>
                                                <ul className="countdown list-inline d-flex justify-content-between" data-countdown="2020/03/23" />
                                                <CommingSoonCounter time={(Math.abs(new Date(2021, 5, 28, 0, 0, 0, 0) - new Date()) / 1000)} />

                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 md-mt-5 text-lg-right">
                                            <img className="img-fluid d-inline" src={require(`../../../assets/images/coming-soon-img.png`)} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*coming soon end*/}
            </div>

        );
    }
}

export default ComingSoon;