import {
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import React from "react";
import SelectOption from "./common/SelectOption";
import { useFormik } from "formik";
import * as yup from "yup";
import { AccountCircle } from "@material-ui/icons";
import LocationOnIcon from "@material-ui/icons/LocationOn";

const dataArr = [
  {},
  {
    title: "State",
    name: "state",
    options: [
      {
        id: 0,
        value: 0,
        valueLabel: "J & K"
      },
      {
        id: 1,
        value: 1,
        valueLabel: "Rajasthan"
      },
      {
        id: 2,
        value: 2,
        valueLabel: "Tamil Nadu"
      },
      {
        id: 2,
        value: 3,
        valueLabel: "Telangana"
      },
      {
        id: 2,
        value: 4,
        valueLabel: "Maharashtra"
      }
    ]
  },
  {
    title: "State",
    name: "pState",
    options: [
      {
        id: 0,
        value: 0,
        valueLabel: "J & K"
      },
      {
        id: 1,
        value: 1,
        valueLabel: "Rajasthan"
      },
      {
        id: 2,
        value: 2,
        valueLabel: "Tamil Nadu"
      },
      {
        id: 2,
        value: 3,
        valueLabel: "Telangana"
      },
      {
        id: 2,
        value: 4,
        valueLabel: "Maharashtra"
      }
    ]
  },
  {
    title: "City",
    name: "city",
    options: [
      {
        id: 0,
        value: 1,
        valueLabel: "Mysterious Island"
      }
    ]
  },
  {
    title: "PIN Code",
    name: "pinCode",
    options: [
      {
        id: 0,
        value: 1,
        valueLabel: "Standard"
      }
    ]
  }
];
const validationSchema = yup.object({
  // flatNum: yup.string().required("Flat Number is required"),
  // areaName: yup.string().required("Area Name is required"),
  // pFlatNum: yup.string().required("Flat Number Number is rquired"),
  // town: yup.string().required("Town is required"),
  // pinCode: yup.number().required("PIN Code is required"),
  // pAreaName: yup.string().required("Area Name is required"),
  // state: yup.number().required("State is required"),
  // pState: yup.number().required("State is required"),
  // pTown: yup.string().required("Town is required"),
  // pPINCode: yup.number().required("PIN Code is required")
});
const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    height: "2px"
  },
  formControl: {
    width: "100%"
  }
}));
function Address(props) {
  const { setCurrentTab } = props;
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      flatNum: "",
      areaName: "",
      pFlatNum: "",
      town: "",
      pinCode: "",
      pAreaName: "",

      state: "",
      pState: "",
      city: "",
      pPINCode: "",
      pTown: ""
    },
    validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      setCurrentTab((prev) => prev + 1);
    }
  });
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Typography variant="h4">
        {/* <span
          style={{
            fontWeight: "bold",
            display: "block",
            margin: "20px 0 14px 0",
            textAlign: "center"
          }}
        >
          Address Details
        </span> */}
      </Typography>
      <Divider variant="fullWidth" className={classes.divider} />
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <Container maxWidth="sm" style={{ textAlign: "center" }}>
                <Typography variant="body1" color="primary">
                  ----- Current Address -----
                </Typography>
              </Container>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="Flat Number"
                name="flatNum"
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.flatNum}
                helperText={formik.touched.flatNum && formik.errors.flatNum}
                error={formik.touched.flatNum && Boolean(formik.errors.flatNum)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="Area Name"
                name="areaName"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LocationOnIcon />
                    </InputAdornment>
                  )
                }}
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.areaName}
                helperText={formik.touched.areaName && formik.errors.areaName}
                error={
                  formik.touched.areaName && Boolean(formik.errors.areaName)
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="Town/City/Village"
                name="town"
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.town}
                helperText={formik.touched.town && formik.errors.town}
                error={formik.touched.town && Boolean(formik.errors.town)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <SelectOption
                item={dataArr[1]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.state}
                touched={formik.touched.state}
                error={formik.errors.state}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="PIN Code"
                name="pinCode"
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pinCode}
                helperText={formik.touched.pinCode && formik.errors.pinCode}
                error={formik.touched.pinCode && Boolean(formik.errors.pinCode)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <Container maxWidth="sm" style={{ textAlign: "center" }}>
                <Typography variant="body1" color="primary">
                  ----- Permanent Address -----
                </Typography>
              </Container>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="Flat Number"
                name="pFlatNum"
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pFlatNum}
                helperText={formik.touched.pFlatNum && formik.errors.pFlatNum}
                error={
                  formik.touched.pFlatNum && Boolean(formik.errors.pFlatNum)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="Area Name"
                name="pAreaName"
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LocationOnIcon />
                    </InputAdornment>
                  )
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pAreaName}
                helperText={formik.touched.pAreaName && formik.errors.pAreaName}
                error={
                  formik.touched.pAreaName && Boolean(formik.errors.pAreaName)
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="Town/City/Village"
                name="pTown"
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pTown}
                helperText={formik.touched.pTown && formik.errors.pTown}
                error={formik.touched.pTown && Boolean(formik.errors.pTown)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectOption
                item={dataArr[2]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pState}
                touched={formik.touched.pState}
                error={formik.errors.pState}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="PIN Code"
                name="pPINCode"
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pPINCode}
                helperText={formik.touched.pPINCode && formik.errors.pPINCode}
                error={
                  formik.touched.pPINCode && Boolean(formik.errors.pPINCode)
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Next
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                onClick={() => setCurrentTab((prev) => prev - 1)}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default Address;
