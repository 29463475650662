import {
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import React from "react";
import SelectOption from "./common/SelectOption";
import { useFormik } from "formik";
import * as yup from "yup";
import India from "./images/india.webp";
import UK from "./images/uk.webp";
import US from "./images/us.webp";

const dataArr = [
  {
    title: "Country",
    name: "country",
    options: [
      {
        id: 0,
        value: 0,
        valueLabel: (
          <Container
            disableGutters
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src={India} alt="indian flag" style={{ height: "20px" }} />{" "}
            <span>&nbsp;India</span>
          </Container>
        )
      },
      {
        id: 1,
        value: 1,
        valueLabel: (
          <Container
            disableGutters
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src={UK} alt="UK flag" style={{ height: "20px" }} />{" "}
            <span>&nbsp;UK</span>
          </Container>
        )
      },
      {
        id: 2,
        value: 2,
        valueLabel: (
          <Container
            disableGutters
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src={US} alt="USA flag" style={{ height: "20px" }} />{" "}
            <span>&nbsp;US</span>
          </Container>
        )
      }
    ]
  },
  {
    title: "State",
    name: "state",
    options: [
      {
        id: 0,
        value: 0,
        valueLabel: "J & K"
      },
      {
        id: 1,
        value: 1,
        valueLabel: "Rajasthan"
      },
      {
        id: 2,
        value: 2,
        valueLabel: "Tamil Nadu"
      },
      {
        id: 2,
        value: 3,
        valueLabel: "Telangana"
      },
      {
        id: 2,
        value: 4,
        valueLabel: "Maharashtra"
      }
    ]
  },
  {
    title: "City",
    name: "city",
    options: [
      {
        id: 0,
        value: 1,
        valueLabel: "Mysterious Island"
      }
    ]
  },
  {
    title: "PIN Code",
    name: "pinCode",
    options: [
      {
        id: 0,
        value: 1,
        valueLabel: "Standard"
      }
    ]
  },
  {
    title: "Select Parent/Guardian",
    name: "parentGuardian",
    options: [
      {
        id: 0,
        value: 1,
        valueLabel: "Parent"
      },
      {
        id: 1,
        value: 2,
        valueLabel: "Guardian"
      }
    ]
  },
  {
    title: "Are You Our Employee",
    name: "isEmployee",
    options: [
      {
        id: 0,
        value: 1,
        valueLabel: "Yes"
      },
      {
        id: 1,
        value: 2,
        valueLabel: "No"
      }
    ]
  },
  {
    title: "Annual Income",
    name: "income",
    options: [
      {
        id: 0,
        value: 1,
        valueLabel: "Less than 1 Lakh"
      },
      {
        id: 1,
        value: 2,
        valueLabel: "1 Lakh - 5 Lakh(excluded)"
      },
      {
        id: 2,
        value: 3,
        valueLabel: "5 Lakh - 10 Lakh(excluded)"
      },
      {
        id: 3,
        value: 4,
        valueLabel: "More than 10 Lakh"
      }
    ]
  }
];
const validationSchema = yup.object({
  // fatherName: yup.string().required("Father's Name is required"),
  // fatherEmail: yup
  //   .string()
  //   .email("Enter valid Email")
  //   .required("Father's Email is required"),
  // fatherMobile: yup.number().required("Father's Mobile Number is rquired"),
  // motherName: yup.string().required("Mother's Name is required"),
  // motherEmail: yup
  //   .string()
  //   .email("Enter valid Email")
  //   .required("Mother's Email is required"),
  // motherMobile: yup.number().required("Mother's Mobile Number is required"),
  // country: yup.number().required("Country is required"),
  // state: yup.number().required("State is required"),
  // city: yup.number().required("City is required"),
  // pinCode: yup.number().required("PIN Code is required")
});
const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    height: "2px"
  },
  formControl: {
    width: "100%"
  }
}));
function Parent(props) {
  const { setCurrentTab } = props;
  const classes = useStyles();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const formik = useFormik({
    initialValues: {
      fatherName: "",
      fatherEmail: "",
      fatherMobile: "",
      motherName: "",
      motherEmail: "",
      motherMobile: "",
      parentGuardian: "",

      country: "",
      state: "",
      city: "",
      pinCode: ""
    },
    validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      setCurrentTab((prev) => prev + 1);
    }
  });
  const Parents = () => {
    return (
      <>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            <Container
              maxWidth="sm"
              style={{
                textAlign: "center"
              }}
            >
              <Typography variant="body1" color="primary">
                ----- Father Details -----
              </Typography>
            </Container>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Father's Name"
              name="fatherName"
              variant="outlined"
              fullWidth
              //onChange={formik.handleChange}
              //onBlue={formik.handleBlur}
              //value={formik.values.fatherName}
              helperText={formik.touched.fatherName && formik.errors.fatherName}
              error={
                formik.touched.fatherName && Boolean(formik.errors.fatherName)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Father's Email"
              name="fatherEmail"
              variant="outlined"
              fullWidth
              //onChange={formik.handleChange}
              //onBlue={formik.handleBlur}
              //value={formik.values.fatherEmail}
              helperText={
                formik.touched.fatherEmail && formik.errors.fatherEmail
              }
              error={
                formik.touched.fatherEmail && Boolean(formik.errors.fatherEmail)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Father's Mobile"
              name="fatherMobile"
              variant="outlined"
              fullWidth
              //onChange={formik.handleChange}
              //onBlue={formik.handleBlur}
              //value={formik.values.fatherMobile}
              helperText={
                formik.touched.fatherMobile && formik.errors.fatherMobile
              }
              error={
                formik.touched.fatherMobile &&
                Boolean(formik.errors.fatherMobile)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Father's Occupation"
              name="fatherOccupation"
              variant="outlined"
              fullWidth
              //onChange={formik.handleChange}
              //onBlue={formik.handleBlur}
              //value={formik.values.fatherOccupation}
              helperText={
                formik.touched.fatherOccupation &&
                formik.errors.fatherOccupation
              }
              error={
                formik.touched.fatherOccupation &&
                Boolean(formik.errors.fatherOccupation)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            <Container maxWidth="sm" style={{ textAlign: "center" }}>
              <Typography variant="body1" color="primary">
                ----- Mother Details -----
              </Typography>
            </Container>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Mother's Name"
              name="motherName"
              variant="outlined"
              fullWidth
              //onChange={formik.handleChange}
              //onBlue={formik.handleBlur}
              //value={formik.values.motherName}
              helperText={formik.touched.motherName && formik.errors.motherName}
              error={
                formik.touched.motherName && Boolean(formik.errors.motherName)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Mother's Email"
              name="motherEmail"
              variant="outlined"
              fullWidth
              //onChange={formik.handleChange}
              //onBlue={formik.handleBlur}
              //value={formik.values.motherEmail}
              helperText={
                formik.touched.motherEmail && formik.errors.motherEmail
              }
              error={
                formik.touched.motherEmail && Boolean(formik.errors.motherEmail)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Mother's Mobile"
              name="motherMobile"
              variant="outlined"
              fullWidth
              //onChange={formik.handleChange}
              //onBlue={formik.handleBlur}
              //value={formik.values.motherMobile}
              helperText={
                formik.touched.motherMobile && formik.errors.motherMobile
              }
              error={
                formik.touched.motherMobile &&
                Boolean(formik.errors.motherMobile)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Mother's Occupation"
              name="motherOccupation"
              variant="outlined"
              fullWidth
              //onChange={formik.handleChange}
              //onBlue={formik.handleBlur}
              //value={formik.values.motherOccupation}
              helperText={
                formik.touched.motherOccupation &&
                formik.errors.motherOccupation
              }
              error={
                formik.touched.motherOccupation &&
                Boolean(formik.errors.motherOccupation)
              }
            />
          </Grid>
        </Grid>
      </>
    );
  };
  const Guardian = () => {
    return (
      <>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            <Container
              maxWidth="sm"
              style={{
                textAlign: "center"
              }}
            >
              <Typography variant="body1" color="primary">
                ----- Guardian Details -----
              </Typography>
            </Container>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Guardian's Name"
              name="guardianName"
              variant="outlined"
              fullWidth
              //onChange={formik.handleChange}
              //onBlue={formik.handleBlur}
              //value={formik.values.guardianName}
              helperText={
                formik.touched.guardianName && formik.errors.guardianName
              }
              error={
                formik.touched.guardianName &&
                Boolean(formik.errors.guardianName)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Guardian's Email"
              name="guardianEmail"
              variant="outlined"
              fullWidth
              //onChange={formik.handleChange}
              //onBlue={formik.handleBlur}
              //value={formik.values.guardianEmail}
              helperText={
                formik.touched.guardianEmail && formik.errors.guardianEmail
              }
              error={
                formik.touched.guardianEmail &&
                Boolean(formik.errors.guardianEmail)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Guardian's Mobile"
              name="guardianMobile"
              variant="outlined"
              fullWidth
              //onChange={formik.handleChange}
              //onBlue={formik.handleBlur}
              //value={formik.values.guardianMobile}
              helperText={
                formik.touched.guardianMobile && formik.errors.guardianMobile
              }
              error={
                formik.touched.guardianMobile &&
                Boolean(formik.errors.guardianMobile)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Guardian's Occupation"
              name="guradianOccupation"
              variant="outlined"
              fullWidth
              //onChange={formik.handleChange}
              //onBlue={formik.handleBlur}
              //value={formik.values.guradianOccupation}
              helperText={
                formik.touched.guradianOccupation &&
                formik.errors.guradianOccupation
              }
              error={
                formik.touched.guradianOccupation &&
                Boolean(formik.errors.guradianOccupation)
              }
            />
          </Grid>
        </Grid>
      </>
    );
  };
  const Append = () => {
    return (
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item sm={6} xs={12}>
          <SelectOption
            item={dataArr[5]}
            onChange={formik.handleChange}
            onBlue={formik.handleBlur}
            value={formik.values.isEmployee}
            touched={formik.touched.isEmployee}
            error={formik.errors.isEmployee}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectOption
            item={dataArr[6]}
            onChange={formik.handleChange}
            onBlue={formik.handleBlur}
            value={formik.values.income}
            touched={formik.touched.income}
            error={formik.errors.income}
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <div>
      <Typography variant="h4">
        {/* <span
          style={{
            fontWeight: "bold",
            display: "block",
            textAlign: "center",
            margin: "20px 0 14px 0"
          }}
        >
          Parent Details
        </span> */}
      </Typography>
      <Divider variant="fullWidth" className={classes.divider} />
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item sm={12} xs={12}>
              <SelectOption
                item={dataArr[4]}
                onChange={formik.handleChange}
                onBlue={formik.handleBlur}
                value={formik.values.parentGuardian}
                touched={formik.touched.parentGuardian}
                error={formik.errors.parentGuardian}
              />
            </Grid>
          </Grid>
          {!formik.values.parentGuardian ? null : formik.values
              .parentGuardian === 1 ? (
            <>
              <Parents />
              <Append />{" "}
            </>
          ) : (
            <>
              <Guardian />
              <Append />{" "}
            </>
          )}

          {/* <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <Container maxWidth="sm" style={{ textAlign: "center" }}>
                <Typography variant="body1" color="primary">
                  ----- LOCATION DETAILS -----
                </Typography>
              </Container>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item sm={6} xs={12}>
              <SelectOption
                item={dataArr[0]}
                //onChange={formik.handleChange}
                //onBlue={formik.handleBlur}
                //value={formik.values.country}
                touched={formik.touched.country}
                error={formik.errors.country}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <SelectOption
                item={dataArr[1]}
                //onChange={formik.handleChange}
                //onBlue={formik.handleBlur}
                //value={formik.values.state}
                touched={formik.touched.state}
                error={formik.errors.state}
              />
            </Grid>
          </Grid>
        */}
          {/* <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item sm={6} xs={12}>
              <SelectOption
                item={dataArr[2]}
                //onChange={formik.handleChange}
                //onBlue={formik.handleBlur}
                //value={formik.values.city}
                touched={formik.touched.city}
                error={formik.errors.city}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <SelectOption
                item={dataArr[3]}
                //onChange={formik.handleChange}
                //onBlue={formik.handleBlur}
                //value={formik.values.pinCode}
                touched={formik.touched.pinCode}
                error={formik.errors.pinCode}
              />
            </Grid>
          </Grid>
        */}
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Next
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                onClick={() => setCurrentTab((prev) => prev - 1)}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default Parent;
