import React, { Component } from "react";
import AdmissionForm from "./AdmissionForm/AdmissionForm";

class Admission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        {/* <Header2 /> */}
        <div className="page-content">
          <AdmissionForm />
        </div>
        {/*body content end*/}
      </div>
    );
  }
}

export default Admission;
