import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class About1 extends Component {
  render() {
    return (
      <div className="container">
        <div className={` row align-items-center justify-content-between ${this.props.reverse ? "flex-row-reverse" : ""} `}>
          <div className="col-12 col-lg-6 mb-6 mb-lg-0 text-center">
            <img src={require(`../../assets/images/about/${this.props.img}`)} alt="Image" className="img-fluid" />
            <h3>{this.props.for}</h3>
          </div>
          <div className="col-12 col-lg-6 col-xl-5">
            <div>
              <h2 className="font-w-6">{this.props.heading}</h2>
              <p className="lead">{this.props.para}</p>
            </div>
            <div className="d-flex flex-wrap justify-content-start">
              <div className="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div className="d-flex align-items-center">
                  <div className="badge-primary-soft rounded p-1">
                    <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </div>
                  <p className="mb-0 ml-3">{this.props.first}</p>
                </div>
              </div>
              <div className="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div className="d-flex align-items-center">
                  <div className="badge-primary-soft rounded p-1">
                    <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </div>
                  <p className="mb-0 ml-3">{this.props.second}</p>
                </div>
              </div>
              <div className="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div className="d-flex align-items-center">
                  <div className="badge-primary-soft rounded p-1">
                    <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </div>
                  <p className="mb-0 ml-3">{this.props.third}</p>
                </div>
              </div>
              <div className="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div className="d-flex align-items-center">
                  <div className="badge-primary-soft rounded p-1">
                    <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </div>
                  <p className="mb-0 ml-3">{this.props.fourth}</p>
                </div>
              </div>
            </div> <Link to="/" className="btn btn-outline-primary mt-5">
              About Learn More
                  </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default About1;