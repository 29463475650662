import { Container, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Address from "./Address";
import Other from "./Other";
import Parent from "./Parent";
import Student from "./Student";
import "./styles.css";
import UpperTabs from "./UpperTabs";
import logo from "./images/schoolLogoNew.jpg";

function AdmissionForm() {
  const [currentTab, setCurrentTab] = useState(0);
  const [checked, setCheckedTab] = useState([0, 0, 0, 0]);
  useEffect(() => {
    for (let i = 0; i < 4; i++) {
      if (i < currentTab)
        setCheckedTab((prev) => {
          let temp = [...prev];
          temp[i] = 1;
          return temp;
        });
      else
        setCheckedTab((prev) => {
          let temp = [...prev];
          temp[i] = 0;
          return temp;
        });
    }
    console.log(checked);
  }, [currentTab]);
  return (
    <>
      <Container
        disableGutters
        maxWidth="md"
        style={{
          marginBottom: "40px",
          border: "1px solid rgba(0,0,0,.4)",
          paddingBottom: "20px"
        }}
      >
        <div
          style={{
            textAlign: "center",
            paddingTop: "20px",
            marginBottom: "20px"
          }}
        >
          <Grid container spacing={2} alignItems="center" justify="center">
            <Grid item>
              <img src={logo} style={{ maxWidth: "100px" }} alt="school logo" />
            </Grid>
            <Grid item>
              <Typography variant="h5">
                <b style={{ color: "#f50057" }}>CAMBRIDGE CONVENT SCHOOL</b>
              </Typography>
              <Typography variant="body1">
                <b>(Recognised by the Govt. of TN)</b>
              </Typography>
              <Typography variant="body2">
                <b>
                  Dilsukhnagar | RK Puram | Vanasthalipuram | Karmanghat |
                  Badangpet
                </b>
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Typography variant="h4">
          <strong
            style={{
              display: "block",
              textAlign: "center",
              marginBottom: "26px",
              fontSize: "1.1em"
            }}
          >
            Admission Form
          </strong>
        </Typography>
        <UpperTabs checked={checked} />
        {currentTab === 0 ? (
          <Student setCurrentTab={setCurrentTab} />
        ) : currentTab === 1 ? (
          <Parent setCurrentTab={setCurrentTab} />
        ) : currentTab === 2 ? (
          <Address setCurrentTab={setCurrentTab} />
        ) : (
          <Other setCurrentTab={setCurrentTab} />
        )}
      </Container>
    </>
  );
}

export default AdmissionForm;
