import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

class Footer extends Component {
    render() {
        return (
            <footer className="py-11 bg-primary position-relative" data-bg-img="assets/images/bg/03.png">
                <div className="shape-1" style={{ height: '150px', overflow: 'hidden' }}>
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: 'none', fill: '#fff' }} />
                    </svg>
                </div>
                <Container className=" mt-11">
                    <Row>
                        {/* <div className="col-12 col-lg-5 col-xl-4 mr-auto mb-6 mb-lg-0">
                            <div className="subscribe-form bg-warning-soft p-5 rounded">
                                <h5 className="mb-4 text-white">Newsletter</h5>
                                <h6 className="text-light">Subscribe Our Newsletter</h6>
                                <form id="mc-form" className="group">
                                    <input type="email" name="EMAIL" className="email form-control" id="mc-email" placeholder="Email Address" required style={{ height: '60px' }} />
                                    <input className="btn btn-outline-light btn-block mt-3 mb-2" type="submit" name="subscribe" defaultValue="Subscribe" />
                                </form> <small className="text-light">Get started for 1 Month free trial No Purchace required.</small>
                            </div>
                        </div> */}
                        <div className="col-12 col-lg-12 col-xl-12 text-center">
                            <Row className="justify-content-around">
                                <Col className="col-12 col-md-3 col-sm-6 navbar-dark mt-0">
                                    <h5 className="mb-4 text-white">DigitalPatashala</h5>
                                    <ul className="navbar-nav list-unstyled mb-0">
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">How it Works</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">Features</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">Brochure</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">Why DigitalPatashala</Link>
                                        </li>
                                        <li className="nav-item"><Link className="nav-link" to="#">Our Approach</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col className="col-12 col-md-3 col-sm-6 mt-6 mt-sm-0 navbar-dark mt-0">
                                    <h5 className="mb-4 text-white">Help Desk</h5>
                                    <ul className="navbar-nav list-unstyled mb-0">
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">Tech Help</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">Help Videos</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">Latest Releases</Link>
                                        </li>
                                        <li className="nav-item"><Link className="nav-link" to="#">Security</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col className="col-12  col-md-3 col-sm-6 mt-6 mt-sm-0 navbar-dark mt-0">
                                    <h5 className="mb-4 text-white">Company</h5>
                                    <ul className="navbar-nav list-unstyled mb-0">
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">About Us</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">Careers</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">FAQs</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">Blog</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">Resellers</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">Partners</Link>
                                        </li>
                                        <li className="nav-item"><Link className="nav-link" to="#">Clients</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col className="col-12 col-md-3 col-sm-6 mt-6 mt-sm-0 navbar-dark mt-0">
                                    <h5 className="mb-4 text-white">Policies</h5>
                                    <ul className="navbar-nav list-unstyled mb-0">
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">Privacy Policy</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">Terms &amp; Conditions</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="#">Disclaimer</Link>
                                        </li>

                                        <li className="nav-item"><Link className="nav-link" to="#">Sitemap</Link>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            <Row className="mt-5" >
                                <Col className="col-12 col-sm-6">
                                    <Link className="footer-logo text-white h2 mb-0" to="#">
                                        Follow Us On
                                    </Link>
                                </Col>
                                <Col className="col-12 col-sm-6 mt-6 mt-sm-0">
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item"><Link className="text-light ic-2x" to="#">
                                            <i className="la la-facebook" /></Link>
                                        </li>
                                        <li className="list-inline-item"><Link className="text-light ic-2x" to="#" >
                                            <i className="la la-dribble" /></Link>
                                        </li>
                                        <li className="list-inline-item"><Link className="text-light ic-2x" to="#">
                                            <i className="la la-instagram" /> </Link>
                                        </li>
                                        <li className="list-inline-item"><Link className="text-light ic-2x" to="#">
                                            <i className="la la-twitter" /> </Link>
                                        </li>
                                        <li className="list-inline-item"><Link className="text-light ic-2x" to="#">
                                            <i className="la la-linkedin" /> </Link>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <Row className="text-white text-center mt-8">
                        <Col>
                            <hr className="mb-8" />© 2021 DigitalPatashala Solutions Pvt.Ltd. All Rights
Reserved.</Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}

export default Footer;