import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Modal,
  Typography
} from "@material-ui/core";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& span.uploadButton": {
      width: "160px",
      marginTop: "10px",
      overflow: "hidden"
    }
  },
  imageBox: {
    width: "160px",
    height: "160px",
    background: "rgba(0,0,0,.1)",
    borderRadius: "4px",
    border: "2px solid rgba(0,0,0,.1)",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto"
    }
  },
  cropButton: {
    marginBottom: "20px",
    display: "block",
    margin: "0 auto"
  },
  photoArea: {
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  uploadButton: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
      textAlign: "center"
    }
  },
  instructions: {
    "& .MuiTypography-root": {
      marginBottom: "6px"
    }
  },
  modal: {
    maxWidth: "100%",
    boxSizing: "border-box",
    overflow: "hidden"
  },
  modalInner: {
    marginTop: "10%",
    textAlign: "center",
    minWidth: "70%",
    minHeight: "50%",
    width: "56%",
    height: "66%",
    maxWidth: "100%",
    maxHeight: "90%",
    backgroundColor: "#fff",
    padding: "10px",
    display: "flex",
    direction: "column",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: "80%"
    }
  }
}));

function UploadPhoto() {
  const classes = useStyles();
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 1 / 1 });
  const previewCanvasRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const [clickedCrop, setClickedCrop] = useState(false);

  const handleUpload = (event) => {
    console.log();
    let file = event.target.files[0];
    if (file) {
      console.log("file size is ", file.size / 1024);
      const size = file.size / 1024;
      if (size < 2 * 1024) {
        const reader = new FileReader();
        let url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
          setUploadedFile(reader.result);
        };
        console.log(url); // Would see a path?

        setUploadedFile(event.target.files[0]);
      } else {
        alert("Sorry the file size must be less than 2MB");
      }
    }
  };
  const cropImg = (event) => { };
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  useEffect(() => {
    if (!completedCrop && !previewCanvasRef.current && !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    canvas.maxWidth = "100%";
    canvas.maxHeight = "100%";

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  return (
    <>
      <div
        className={classes.root}
        style={{ marginTop: "32px", textAlign: "left" }}
      >
        {uploadedFile && !clickedCrop ? (
          // <div
          //   style={{
          //     width: `${document.body.clientWidth}px`,
          //     height: `${document.body.clientHeight}px`,
          //     backgroundColor: "rgba(0,0,0,.9)",
          //     position: "absolute",
          //     left: "0",
          //     top: "0",
          //     overflow: "scroll",
          //     zIndex: "100",

          //     paddingBottom: "40px"
          //   }}
          // >
          <Modal
            open={uploadedFile && !clickedCrop}
            // onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disableAutoFocus
            className={classes.modal}
          >
            <Container className={classes.modalInner}>
              <div
                style={{
                  maxWidth: "98%",
                  margin: "0 auto",
                  overflow: "scroll",
                  overflowX: "hidden"
                }}
              >
                <Container
                  style={{
                    border: "1px solid rgba(0,0,0,.1)",
                    padding: "4px",
                    maxWidth: "100%"
                  }}
                >
                  <ReactCrop
                    src={uploadedFile}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                    style={{
                      marginBottom: "10px",
                      minWidth: "300px",
                      maxWidth: "100%",
                      maxHeight: "100%"
                    }}
                    imgStyle={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      height: "100px"
                    }}
                  />
                </Container>
              </div>
              <Button
                onClick={() => {
                  if (uploadedFile) setClickedCrop(true);
                  console.log("I jsut clicked crop");
                }}
                variant="contained"
                color="primary"
                className={classes.cropButton}
              >
                Crop & Save
              </Button>
            </Container>
          </Modal>
        ) : // </div>
          null}
        <Grid
          container
          spacing={2}
          alignItems="center"
          className={classes.photoArea}
        >
          <Grid item xs={12} sm={5} md={4}>
            <div className={classes.imageBox}>
              {uploadedFile ? (
                <canvas
                  ref={previewCanvasRef}
                  // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                  style={{
                    // width: Math.round(completedCrop?.width ?? 0),
                    // height: Math.round(completedCrop?.height ?? 0)
                    width: "100%"
                  }}
                />
              ) : null}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={8}
            direction="column"
            spacing="1"
            className={classes.instructions}
          >
            <Typography variant="body1">Photo Instructions</Typography>
            <Typography variant="body1" color="textSecondary">
              1. JPG/PNG/BMP Format only
            </Typography>
            <Typography variant="body1" color="textSecondary">
              2. File size must be less than 2MB
            </Typography>
          </Grid>
        </Grid>
        <label htmlFor="image-upload" className={classes.uploadButton}>
          <input
            accept=".jpg, .jpeg, .png, .bmp"
            type="file"
            id="image-upload"
            name="image-upload"
            style={{ display: "none" }}
            onChange={(e) => handleUpload(e)}
          />
          <Button
            component="span"
            className="uploadButton"
            variant="contained"
            color="primary"
            onClick={() => setClickedCrop(false)}
          >
            Upload Photo
          </Button>
        </label>
      </div>
    </>
  );
}

export default UploadPhoto;
