import {
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import SelectOption from "./common/SelectOption";
import { useFormik } from "formik";
import * as yup from "yup";
import SignatureCanvas from "react-signature-canvas";

import PaypalBtn from "./paypal/PaypalBtn"
// import RadioButton from "./RadioButton";
// import { useHistory, Redirect } from "react-router-dom";
import FileUpload from "./Other/FileUpload";
import Aadhar from "./images/aadharicon.jpg";
const dataArr = [
  {
    title: "Mother Tongue",
    name: "motherTongue",
    options: [
      {
        id: 0,
        value: 0,
        valueLabel: "English"
      },
      {
        id: 1,
        value: 1,
        valueLabel: "Hindi"
      },
      {
        id: 2,
        value: 2,
        valueLabel: "Sanskrit"
      },
      {
        id: 3,
        value: 3,
        valueLabel: "Telugu"
      },
      {
        id: 4,
        value: 4,
        valueLabel: "Tamil"
      }
    ]
  },
  {
    title: "Nationality",
    name: "nationality",
    options: [
      {
        id: 0,
        value: 0,
        valueLabel: "Indian"
      },
      {
        id: 1,
        value: 1,
        valueLabel: "American"
      },
      {
        id: 2,
        value: 2,
        valueLabel: "Canadian"
      }
    ]
  },
  {
    title: "How did you hear about us?",
    name: "heardMethod",
    options: [
      {
        id: 0,
        value: 0,
        valueLabel: "Through Website"
      },
      {
        id: 1,
        value: 1,
        valueLabel: "Through Posters"
      },
      {
        id: 2,
        value: 2,
        valueLabel: "Relatives and Friends"
      },
      {
        id: 3,
        value: 3,
        valueLabel: "Google Search"
      },
      {
        id: 4,
        value: 4,
        valueLabel: "News"
      }
    ]
  }
];
const validationSchema = yup.object({
  // birthPlace: yup.string().required("Place of Birth is required"),
  // motherTongue: yup.number().required("Mother Tongue is required"),
  // nationality: yup.number().required("Nationality is required"),
  // aadhar: yup.number().required("Aadhar Card Number is required"),
  // heardMethod: yup.number().required("Select a value")
});
const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    height: "2px"
  },
  formControl: {
    width: "100%"
  },
  grayArea: {
    "& .MuiButton-root": {
      background: "#fff"
    }
  },
  label: {
    "& .MuiButton-label": {
      color: "rgba(0,0,0,.8)!important"
    }
  }
}));
function Other(props) {
  const { setCurrentTab } = props;
  const classes = useStyles();
  const esRef = useRef();
  const [successful, setSuccessful] = useState(false);
  const [showPaypal, setShowPaypal] = useState(false);

  const formik = useFormik({
    initialValues: {
      birthPlace: "",
      nationality: "",
      heardMethod: "",
      motherTongue: "",
      aadhar: ""
    },
    validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      // setCurrentTab((prev) => prev + 1);
      setSuccessful(true);
    }
  });
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  React.useEffect(() => {
    if (successful)
      setShowPaypal(true);
  }, [successful])

  return (
    <div>
      <Typography variant="h4">
        {/* <span
          style={{
            fontWeight: "bold",
            display: "block",
            margin: "20px 0 14px 0",
            textAlign: "center"
          }}
        >
          Other Details
        </span> */}
      </Typography>
      <Divider variant="fullWidth" className={classes.divider} />
      <Container>
        <form>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <Container maxWidth="sm" style={{ textAlign: "center" }}>
                <Typography variant="body1" color="primary">
                  ----- Upload Documents -----
                </Typography>
              </Container>
            </Grid>
          </Grid>
          <div
            className={`top-navigation ${classes.grayArea}`}
            style={{ paddingBottom: "20px" }}
          >
            <Container>
              <FileUpload
                title={"Address Proof"}
                buttonName={"File Upload"}
                id={1}
              />
              <FileUpload
                title={"Age Proof"}
                buttonName={"File Upload"}
                id={2}
              />
              <FileUpload
                title={"Caste Certificate"}
                buttonName={"File Upload"}
                id={3}
              />
              <FileUpload
                title={"Income Tax Return"}
                buttonName={"File Upload"}
                id={4}
              />
              <FileUpload
                title={"Score Card (last year exam)"}
                buttonName={"File Upload"}
                id={5}
              />
              <FileUpload
                title={"Transfer Certificate"}
                buttonName={"File Upload"}
                id={6}
              />
            </Container>
          </div>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="Place of Birth"
                name="birthPlace"
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.birthPlace}
                helperText={
                  formik.touched.birthPlace && formik.errors.birthPlace
                }
                error={
                  formik.touched.birthPlace && Boolean(formik.errors.birthPlace)
                }
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <SelectOption
                item={dataArr[0]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.motherTongue}
                touched={formik.touched.motherTongue}
                error={formik.errors.motherTongue}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item sm={6} xs={12}>
              <SelectOption
                item={dataArr[1]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nationality}
                touched={formik.touched.nationality}
                error={formik.errors.nationality}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="Aadhar Card No."
                name="aadhar"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <LocationOnIcon /> */}
                      <img
                        src={Aadhar}
                        alt="indian flag"
                        style={{ height: "20px" }}
                      />{" "}
                    </InputAdornment>
                  )
                }}
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.aadhar}
                helperText={formik.touched.aadhar && formik.errors.aadhar}
                error={formik.touched.aadhar && Boolean(formik.errors.aadhar)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item sm={12} xs={12}>
              <SelectOption
                item={dataArr[2]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.heardMethod}
                touched={formik.touched.heardMethod}
                error={formik.errors.heardMethod}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{ marginTop: "20px", textAlign: "left" }}
          >
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Is there any medical information related to the student that
                  you would like the school to be aware of?
                </FormLabel>

                <RadioGroup
                  row
                  aria-label="position"
                  name="medical"
                  defaultValue="no"
                >
                  &nbsp;
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "20px", textAlign: "left" }}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Do you want to request transportation service for the student?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  name="transportation"
                  defaultValue="yes"
                >
                  &nbsp;
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              marginTop: "20px",
              position: "relative",
              border: "1px solid black"
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                <span style={{ fontWeight: "bold" }}>Undertaking</span>
              </Typography>
              <div style={{}}>
                <Typography variant="body1" style={{ display: "inline-block" }}>
                  I&nbsp;
                </Typography>
                <input type="text" required name="fatherMother" />

                <Typography variant="body1" style={{ display: "inline" }}>
                  &nbsp; FATHER / MOTHER / GUARDIAN OF &nbsp;
                  <input type="text" required name="son" />
                  &nbsp;HEREBY DECLARE THAT THE INFORMATION GIVEN ABOVE BY ME IS
                  CORRECT. ADMISSION OF MY CHILD MAY BE CANCELLED IF ANY
                  INFORMATION IS FOUND TO BE FALSE.
                </Typography>
              </div>
              <Container style={{ textAlign: "center" }}>
                <Typography variant="subtitle1">
                  <b>
                    Please sign the below electronic Signature Pad
                    <sup>*</sup>
                  </b>
                </Typography>
                <SignatureCanvas
                  penColor="green"
                  ref={esRef}
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: "sigCanvas"
                  }}
                />
              </Container>
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  bottom: "0",
                  transform: "translate(-50%,0)"
                }}
                className={classes.label}
              >
                <Button
                  variant="contained"
                  onClick={() => esRef.current.clear()}
                >
                  Clear
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6}>
              {/* <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="transportation"
                  defaultValue="yes"
                >
                  &nbsp;
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="primary" />}
                    label="Agree to Terms & Conditions"
                  />
                </RadioGroup>
              </FormControl> */}
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  if (!esRef.current.isEmpty()) {
                    formik.handleSubmit();
                  }
                }}
              >
                Pay & Proceed
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                onClick={() => setCurrentTab((prev) => prev - 1)}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      {(showPaypal && successful) ?
        (<PaypalBtn />) : null}
    </div>
  );
}

export default Other;
