import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class LeadingPlatform extends Component {
    render() {
        return (
            <div className="container">
                <div className="text-center">
                    <h2 className="font-w-6">{this.props.heading}</h2>
                    <br />
                </div>

                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-lg-12">
                        <div>
                            <h4>{this.props.heading1}</h4>

                            <p className="lead">{this.props.para1}</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12">
                        <div>
                            <h4>{this.props.heading2}</h4>

                            <p className="lead">{this.props.para2}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LeadingPlatform;