import React from 'react'
import { Grid, Button, makeStyles } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    label: {
        "& .MuiButton-label": {
            color: "rgba(0,0,0,.8)!important"
        }
    }
}));
function FileUpload(props) {
    const { title, buttonName, id } = props;
    const classes = useStyles();
    return (
        <Grid
            className={classes.label}
            container
            alignItems="center"
            spacing={2}
            style={{ marginTop: "20px" }}
        >
            <Grid item xs={6} style={{ textAlign: "left" }}>
                {title}
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
                <input
                    accept=".pdf"
                    style={{ display: "none" }}
                    id={`file${id}`}
                    name={`file${id}`}
                    type="file"
                />
                <label htmlFor={`file${id}`}>
                    <Button component="span" variant="contained">
                        {buttonName}
                    </Button>
                </label>
            </Grid>
        </Grid>
    )
}

export default FileUpload;