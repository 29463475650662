import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import RadioButton from "./RadioButton";

function UpperTabs(props) {
  const { checked } = props;

  return (
    <div className="top-navigation">
      <Grid container spacing={2} justify="center">
        <Grid item>
          <RadioButton
            label="Student Details"
            name="student"
            checked={checked[0]}
          />
        </Grid>
        <Grid item>
          <RadioButton
            label="Parent Details"
            name="parent"
            checked={checked[1]}
          />
        </Grid>
        <Grid item>
          <RadioButton
            label="Address Details"
            checked={checked[2]}
            name="address"
          />
        </Grid>
        <Grid item>
          <RadioButton
            label="Other Details"
            name="other"
            checked={checked[3]}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default UpperTabs;
